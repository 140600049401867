<template>
  <div v-if="message" class="row">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle">
      <div class="rc-input-good w3-padding-small rc-text-centre" >
        {{ message }} 
      </div>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </div>
</template>

<script>
export default {
  name: 'success-row',
  components: {
  },
  props: {
    message: { type: String, default: "" },
    left: {type: Number, default: 0},
    right: {type: Number, default: 0},
  },
  computed: {
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  }
}
</script>