<template> 
  <div>
  <input v-on:click="pressed"
    class="rc-input-button-green rc-input-good rc-input-width rc-font-small" 
    type=button
    style="padding: 5px;"
    :disabled="isDisabled" :value="label" />
  </div>
</template>

<script>
export default {
  name: 'green-button',
  components: {
  },
  props: {
    label: String,
    disabled: { type: Boolean, default: true },
  },
  data() {
    return {
    };
  },
  computed: {
    isDisabled: function() {
      return this.disabled;
    },
  },
  methods: {
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>
