<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="password">
      <Box>
        <Row v-if="showLabel">
          <Column :width="15">
            <div v-if="isTitleShowing" class="rc-font-small rc-text-label">
              {{ placeholderText() }}
            </div>
            <div v-else class="rc-font-small">
              <div v-if="placeholder != null" > 
                <div class="rc-font-small rc-text-label"> <br> </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row>
          <Column :width="15">
            <Box>
              <Row>
                <Column :width="15">
                  <input 
                    class="rc-input-field-v2 rc-input-width rc-text-value" 
                    autocomplete="off" 
                    role="presentation"
                    :placeholder="placeholderText()" 
                    :disable="disabled" 
                    :type="inputType" 
                    v-model="value"
                    @input="(e) => value = e.target.value" 
                    v-on:keydown.enter="enter" 
                    v-on:keyup="withValue"
                    style="height: 30px; padding-left: 20px; padding-right: 20px;" />
                </Column>
              </Row>
            </Box>
            <Box>
              <Row v-if="shouldShowRequired">
                <Column :width="15">
                <div class="rc-font-small rc-background-required">
                      A value for {{ placeholderText() }} is required
                    </div>
                </Column>
              </Row>
              <Row>
                <Column :width="7" class="rc-font-small rc-text-value rc-text-left">
                  <input type="checkbox" v-on:click="toggleShowPassword"/> <span>Show Password</span>
                </Column>
              
                <Column :width="8">
                  <div class="rc-font-small rc-text-value rc-text-right"><a :href="hintUrl">{{ hint }}</a> <br /></div>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
      </Box>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </Row>
</template>

<script>
import { mapGetters } from 'vuex'
import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import Catalog from '@/domain/session/Catalog.js';
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-password',
  components: {
    Box, Row, Column,
  },
  props: {
    title: String,
    defaultValue: { type: String, default: "" },
    placeholder: String,
    isEmail: { type: Boolean, default: false },
    hint: { type: String, default: "" },
    hintUrl: { type: String, default: "" },
    type: String,
    disabled: { type: Boolean, default: false },
    left: { type: Number, default: 0 },
    right: { type: Number, default: 0 },
    mandatory: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    clearValue: { type: Boolean, default: false },
    showLabel:  {type: Boolean, default: true},
  },
  data() {
    return {
      value: null,
      isTitleShowing: false,
      C: new Catalog(),
      timer: null,
      Strings: StringUtils,
      showPassword: false,
      ready: false,
    };
  },
  watch: {
    value() {
      this.validate();
    },
    clearValue() {
      if (this.clearValue) {
        this.value = null;
      }
    },
    required() {
      if (this.shouldShowRequired) {
        this.$refs.password.scrollIntoView();
      }
    },
  },

  computed: {
    ...mapGetters([
      'domain',
    ]),
    shouldShowRequired: function () {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value)) {
            return true;
          }
        }
      }
      return false;
    },
    initialValue: function () {
      if (this.isEmail) {
        return this.defaultValue;
      }
      return this.defaultValue ?
        ContentUtils.unescape(this.defaultValue) :
        this.defaultValue;
    },
    leftPadding: function () {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function () {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    inputType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
    middle: function () {
      var value = (15 - this.left - this.right);
      var css = "rc-box-col-" + value;
      return css;
    },

  },
  mounted: function () {
    this.value = this.defaultValue ? ContentUtils.decode(this.defaultValue) : this.defaultValue;
    //    this.domain.logger().info("KeyValue Props: {0}", [JSON.stringify(this.$props, null, 1)]);
    //    this.domain.logger().info("KeyValue Data: {0}", [JSON.stringify(this.$data, null, 1)]);
    this.validate();
    this.ready = true;
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    placeholderText: function () {
      if (this.mandatory) {
        return this.C.get(this.placeholder) + "*";
      }
      return this.C.get(this.placeholder);
    },
    withValue: function () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      //this.timer = setInterval(this.emit, 500);
      this.emit();
    },
    emit: function () {
      clearInterval(this.timer)
      this.$emit('value', { 'key': this.title, 'value': ContentUtils.encode(this.value) })
    },
    validate: function () {
      if (this.value != null && this.value.trim().length > 0) {
        this.isTitleShowing = true;
      } else {
        this.isTitleShowing = false;
      }
      if (this.mandatory) {
        this.$emit('requirementsMet', false);
      }
    },
    enter: function () {
      this.emit();
      this.$emit('enter', this.title);
    }
  },

}
</script>
