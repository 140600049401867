<template>
  <div class="container">
    <br />
    <div class="row">
      <div class="rc-box-col-15 rc-text-centre rc-font-title">
        Change Password
      </div>
    </div>
    <ErrorRow :left="5" :right="5" :error="error" />
    <SuccessRow :message="message" />
    <br />
    <div class="row">
      <div class="rc-box-col-5"> </div>
      <div class="rc-box-col-5">
        <div class="password-input-row">
          <KeyPassword
            title="Old Password"
            placeholder="Old Password"
            v-on:value="setValue"
            :disabled="isLoading"
          />
          <KeyPassword
            title="New Password"
            placeholder="New Password"
            v-on:value="setValue"
            :disabled="isLoading"
          />
          <KeyPassword
            title="Confirm Password"
            placeholder="Confirm Password"
            v-on:value="setValue"
            :disabled="isLoading"
          />
        </div>
      </div>
      <div class="rc-box-col-5"> </div>
    </div>
    <div class="row">
      <div class="rc-box-col-5"> </div>

      <div class="rc-box-col-5">
        <ProgressRow :display="isLoading" />
        <GreenButton :disabled="isLoading" v-on:click="confirm" label="Save" />
      </div>
      <div class="rc-box-col-5"> </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import GreenButton from "@/components/button/GreenButton.vue";
import ConstUtils from "@/utils/ConstUtils.js";
import KeyPassword from "@/components/input/KeyPassword.vue";
import CredentialEvents from "@/domain/model/events/CredentialEvents.js";
import ErrorRow from "@/components/row/ErrorRow.vue";
import SuccessRow from '@/components/row/SuccessRow.vue'
import ProgressRow from '@/components/row/ProgressRow.vue'

export default {
  name: "changepassword",
  components: {
    GreenButton,
    ErrorRow,
    KeyPassword,
    SuccessRow,
    ProgressRow,
  },
  data() {
    return {
      error: null,
      isLoading: false,
      oldPasswordError: false,
      newPasswordError: false,
      confirmPasswordError: false,
      oldPasswordValue: "",
      newPasswordValue: "",
      confirmPasswordValue: "",
      message: null,
    };
  },
  computed: {
    ...mapGetters([
      "auth_client",
      "auth_connected",
      "auth_user",
      "changepassword_event",
      "domain",
    ]),
  },
  watch: {
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({
        name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN,
      });
    }
  },
  methods: {
    setValue: function (kvp) {
      this.error = null;
      this.message = null;
      switch (kvp.key) {
        case "Old Password":
          this.oldPasswordValue = kvp.value;
          break;
        case "New Password":
          this.newPasswordValue = kvp.value;
          break;
        case "Confirm Password":
          this.confirmPasswordValue = kvp.value;
          break;
      }
    },
    ...mapMutations(["authClear"]),
    confirm: function () {
      this.newPasswordError = false;
      this.confirmPasswordError = false;
      this.oldPasswordError = false;
      
      if (this.newPasswordValue) {
        if (this.newPasswordValue.length < 8) {
          this.newPasswordError = true;
          this.error = {
            hint: "Your password must be 8 or more characters",
            messageId: "New Password",
          };
          return;
        } else {
          this.newPasswordError = false;
        }

        if (this.newPasswordValue !== this.confirmPasswordValue) {
          this.confirmPasswordError = true;
          this.error = {
            hint: "Your new password does not match the confirmed password",
            messageId: "New Password",
          };
          return;
        } else {
          this.confirmPasswordError = false;
        }

        this.isLoading = true;
        var event = this.domain.events().credentialsSave(this.oldPasswordValue, this.newPasswordValue);
        event.send(this.listener);
      }
    },
    listener: function(event) {
      if (event.is(CredentialEvents.Save.Names.Response)) {
        if (event.error()) {
          this.isLoading = false;
          this.error = event.error();
        } else {
          this.message = "Your password has been successfully changed!";
          this.isLoading = false;
          this.error = null;
        }
      }
    },
  },
};
</script>
