<template>
  <div>
    <div v-if="display">
      <div class="rc-modal-green-user">
        <h1>{{ title }}</h1>
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'progress-row',
  components: {
  },
  props: {
    display: { type: Boolean, default: false },
    message: { type: String, default: "Please wait..." },
    title:   { type: String, default: "Saving" },
  },
}
</script>