
//import ConstUtils from '@/utils/ConstUtils.js';

export default class CredentialEvents {
  
  constructor(domain) {
    this.domain = domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  static Save = {
      Names: {
        Request:  'CredentialsSaveRequestEvent',
        Response: 'CredentialsSaveResponseEvent',
      },

      Errors: {
        IncorrectOldPassword: {
          id: 'IncorrectOldPassword',
          msg: 'The old password is incorrect',
          details: '',
        },
      },

      Request: function(oldPassword, newPassword) {
        var event = {
          oldPassword,
          newPassword,
          handler: 'CredentialsSaveRequestEvent',
          eventName: 'CredentialsSaveRequestEvent',
        }
        return event
      },
    }
  
  static Lost = {
      Names: {
        Request: 'CredentialsLostRequestEvent',
        Response: 'CredentialsLostResponseEvent',
      },

      Errors: {},

      Request: function(email) {
        var event = {
          email: email,
          handler: 'CredentialsLostRequestEvent',
          eventName: 'CredentialsLostRequestEvent',
        }
        return event
      },
    }
}